import { createApp } from 'vue'
import App from './App.vue'
import {router} from "@/router";
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import store from './store'
import './assets/css/global.css'

import { BootstrapVue3  } from 'bootstrap-vue-3'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import './custom.scss'


import axios from 'axios'

const app = createApp(App)
// app.use(BootstrapVue)
// app.use(BootstrapVueIcons)
// Install BootstrapVue
app.use(BootstrapVue3 as any)
// Optionally install the BootstrapVue icon components plugin
// app.use(BootstrapVueIcons as any)
// app.use(IconsPlugin as any)
app.use(router)
app.use(ElementPlus)
app.use(store)

app.mount('#app')