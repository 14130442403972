<template>
  <b-container>
    <b-col>
      <b-nav tabs>
        <b-nav-item active-class="active" :to="{ name: 'Create Task' }"
          >新建任务</b-nav-item
        >
        <b-nav-item active-class="active" :to="{ name: 'My Tasks' }"
          >我的任务</b-nav-item
        >
      </b-nav>
      <div class="pt-4">
        <router-view></router-view>
      </div>
    </b-col>
  </b-container>
</template>

<script>
export default {
  name: 'Task'
}
</script>
