<template>
<!--  <div style="width: 60%;">-->
<!--    <el-row v-show="!isEditing" :style="style.down">-->
<!--      <el-col :span="2">-->
<!--        <el-button type="success" @click="setIsUpdateDialogOpen(true)" plain>新建</el-button>-->
<!--      </el-col>-->
<!--      <el-col :span="2">-->
<!--        <el-button type="warning" @click="isEditing=true" plain>编辑</el-button>-->
<!--      </el-col>-->
<!--    </el-row>-->

<!--    <el-row v-show="isEditing" :style="style.down">-->
<!--      <el-col :span="2">-->
<!--        <el-button type="success" @click="isEditing=false;unselectAll()">取消</el-button>-->
<!--      </el-col>-->
<!--      <el-col :span="2">-->
<!--        <el-button type="danger" @click="isEditing=false; deleteServers();unselectAll();">删除</el-button>-->
<!--      </el-col>-->
<!--    </el-row>-->

<!--    <el-dialog-->
<!--        :title="updateDialogTitle"-->
<!--        :visible.sync="is_update_dialog_open"-->
<!--        @close="closeUpdateDialog"-->
<!--        @confirm="closeUpdateDialog();postServer(server_info)"-->
<!--    >-->
<!--      <el-form>-->
<!--        <el-form-item label="名字：">-->
<!--          <el-input v-model="server_info.username" type="text"></el-input>-->
<!--        </el-form-item>-->

<!--        <el-form-item label="密码：">-->
<!--          <el-input v-model="server_info.password" type="password"></el-input>-->
<!--        </el-form-item>-->

<!--        <el-form-item label="确认密码：">-->
<!--          <el-input v-model="server_info.password2" type="password"></el-input>-->
<!--        </el-form-item>-->
<!--      </el-form>-->
<!--    </el-dialog>-->


<!--    <el-table-->
<!--        :data="isEditing ? servers.slice((currentPage - 1) * perPage, currentPage * perPage) : servers"-->
<!--        style="width: 100%"-->
<!--        stripe-->
<!--    >-->
<!--      <el-table-column-->
<!--          prop="online"-->
<!--          label="Online"-->
<!--          width="180">-->
<!--        <template #default="{row}">-->
<!--          <el-icon v-if="row.online" name="circle-check" class="el-icon-check success-color"></el-icon>-->
<!--          <el-icon v-else name="circle-close" class="el-icon-close danger-color"></el-icon>-->
<!--        </template>-->
<!--      </el-table-column>-->

<!--      <el-table-column-->
<!--          prop="progresses_info"-->
<!--          label="Progresses Info">-->
<!--        <template #default="{row}">-->
<!--          &lt;!&ndash; Add your progresses_info conversion here &ndash;&gt;-->
<!--        </template>-->
<!--      </el-table-column>-->

<!--      <el-table-column-->
<!--          prop="link"-->
<!--          label="Link">-->
<!--        <template #default="{row}">-->
<!--          <el-button type="text" @click="openDetailPage(row.id)">详情</el-button>-->
<!--        </template>-->
<!--      </el-table-column>-->

<!--      <el-table-column-->
<!--          prop="selected"-->
<!--          label="Selected">-->
<!--        <template #default="{row}">-->
<!--          <el-checkbox v-model="row.selected" @change="select(row)"></el-checkbox>-->
<!--        </template>-->
<!--      </el-table-column>-->

<!--      <el-table-column-->
<!--          prop="name"-->
<!--          label="Name">-->
<!--        <template #default="{row}">-->
<!--          {{ row.name.first }} {{ row.name.last }}-->
<!--        </template>-->
<!--      </el-table-column>-->

<!--      <el-table-column-->
<!--          prop="text"-->
<!--          label="Text">-->
<!--        <template #default="{row}">-->
<!--          <el-button size="mini" @click="row.$expand = !row.$expand">-->
<!--            {{ row.$expand ? '收起' : '展开' }}-->
<!--          </el-button>-->
<!--        </template>-->
<!--      </el-table-column>-->

<!--      <el-table-column type="expand">-->
<!--        <template #default="{row}">-->
<!--          <el-card>-->
<!--            {{ row.text }}-->
<!--          </el-card>-->
<!--        </template>-->
<!--      </el-table-column>-->
<!--    </el-table>-->
<!--    <el-pagination-->
<!--        @size-change="handleSizeChange"-->
<!--        @current-change="handleCurrentChange"-->
<!--        :current-page="currentPage"-->
<!--        :page-size="perPage"-->
<!--        layout="prev, pager, next"-->
<!--        :total="servers.length"-->
<!--    >-->
<!--    </el-pagination>-->

<!--    <el-dialog-->
<!--        title="算力机管理"-->
<!--        :visible.sync="is_dialog_open"-->
<!--        ok-only-->
<!--        @close="closeDialog"-->
<!--        @confirm="closeDialog"-->
<!--    >-->
<!--      <div class="text-center">{{dialog_content}}</div>-->
<!--    </el-dialog>-->
<!--  </div>-->
  <div>
    <b-row align-h="start" v-show="!isEditing" :style="style.down">
      <b-col lg="2" cols="3">
        <b-button style="background-color: #7952B3; border: #7952B3;" block variant="primary" @click="setIsUpdateDialogOpen(true)">新建</b-button>
      </b-col>
      <b-col lg="2" cols="3">
        <b-button style="background-color: #7952B3; border: #7952B3;" block variant="primary" @click="isEditing=true">编辑</b-button>
      </b-col>
    </b-row>
    <b-row v-show="isEditing" align-h="end" :style="style.down">
      <b-col lg="2" cols="3">
        <b-button block variant="success" @click="isEditing=false;unselectAll()">取消</b-button>
      </b-col>
      <b-col lg="2" cols="3">
        <b-button block variant="danger" @click="isEditing=false; deleteServers();unselectAll();">删除</b-button>
      </b-col>
    </b-row>
    <b-modal
        :title="updateDialogTitle"
        no-close-on-backdrop
        no-close-on-esc
        :visible="is_update_dialog_open"
        ok-title="确定"
        cancel-title="取消"
        @close="closeUpdateDialog"
        @ok="closeUpdateDialog();postServer(server_info)"
        @cancel="closeUpdateDialog"
        :title-class="{style: {fontSize:'50px'}}"
    >
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group label="名字：" label-cols-md="3" label-align="right">
              <b-form-input v-model="server_info.username" type="text"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="密码：" label-cols-md="3" label-align="right">
              <b-form-input v-model="server_info.password" type="password"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="确认密码：" label-cols-md="3"  label-align="right">
              <b-form-input v-model="server_info.password2" type="password"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>

    <b-table
        striped
        hover
        responsive
        :items="servers"
        :fields="isEditing? fieldsEditing: fields"
        :current-page="currentPage"
        :per-page="perPage"
        outlined
    >
      <template v-slot:cell(online)="row">
        <div class="h2 mb-0">
          <b-icon icon="circle-fill" variant="success" v-if="row.item.online === true"></b-icon>
          <b-icon icon="circle-fill" variant="danger" v-if="row.item.online === false"></b-icon>
        </div>
      </template>

      <template v-slot:cell(progresses_info)="row">
        <!--{{row}}-->
        <b-progress
            v-if="row.item.total_memory > 0 && row.item.online === true"
            :max=row.item.total_memory class="mt-2">
          <template v-if="row.item.processes_info !== null">
            <template v-for="process_info in row.item.processes_info">
              <b-progress-bar
                  :style="{ 'background-color': process_info.color}"
                  :value=process_info.used_memory>
              </b-progress-bar>
            </template>
          </template>
        </b-progress>
      </template>

      <template v-slot:cell(link)="row">
        <div style="white-space: nowrap">
          <b-button
              variant="outline-primary"
              size="sm"
              @click="openDetailPage(row.item.id)"
          >详情</b-button>
        </div>
      </template>

      <template v-slot:cell(selected)="row">
        <b-form-checkbox
            :key="row.item.id"
            :checked="row.item.selected?'yes':'no'"
            @input="select(row.item)"
            value="yes"
            unchecked-value="no"
        ></b-form-checkbox>
      </template>
      <template v-slot:cell(name)="row">{{ row.value.first }} {{ row.value.last }}</template>

      <template v-slot:cell(text)="row">
        <b-button
            size="sm"
            @click="row.toggleDetails"
            variant="outline-primary"
            class="mr-1"
            block
        >{{ row.detailsShowing ? '收起' : '展开'}}</b-button>
      </template>
      <template v-slot:row-details="row">
        <b-card>{{row.item.text}}</b-card>
      </template>
    </b-table>
    <b-pagination
        v-model="currentPage"
        :total-rows="servers.length"
        :per-page="perPage"
        align="right"
        :style="style.pagination"
    ></b-pagination>
    <b-modal
        title="算力机管理"
        no-close-on-esc
        no-close-on-backdrop
        ok-only
        :visible="is_dialog_open"
        ok-title="确定"
        @close="closeDialog"
        @ok="closeDialog"
        :title-class="{style: {fontSize:'50px'}}"
    >
      <div class="text-center">{{dialog_content}}</div>
    </b-modal>
  </div>
</template>

<script>
import { postServer, getServers, deleteServers } from './manage_server'
import {
  closeDialog,
  closeUpdateDialog,
  setIsUpdateDialogOpen
} from '@/utils/index'

export default {
  name: 'ManageServer',
  data () {
    return {
      server_info: {
        username: '',
        password: '',
        password2: ''
      },
      perPage: 8,
      currentPage: 1,
      updateDialogTitle: '新建算力机',
      fields: [
        {
          key: 'online',
          label: '状态',
          class: 'text-center'
        },
        {
          key: 'username',
          label: '服务器名',
          sortable: true,
          class: 'text-center'
        },
        {
          key: 'last_login',
          label: '最后登录时间',
          sortable: true,
          class: 'text-center'
        },
        {
          key: 'progresses_info',
          label: '显存占用情况',
          class: 'text-center'
        },
        {
          key: 'link',
          label: '操作',
          stickyColumn: true,
          class: 'text-center'
        }
      ],
      isEditing: false,
      style: {
        pagination: {
          marginTop: '20px'
        },
        down: {
          marginBottom: '20px'
        }
      },
      errors: '',
      servers: [],
      is_dialog_open: false,
      dialog_content: '',
      is_update_dialog_open: false,
      rua: 'red'
    }
  },
  computed: {
    fieldsEditing: function () {
      let res = [{key: 'selected', label: '', class: 'text-center'}]
      return res.concat(this.fields)
    }
  },
  methods: {

    openDetailPage (id) {
      // window.open('/server_detail/' + id)
      const serverDetailUrl = this.$router.resolve({
        name: 'Server Detail',
        params: { server_id: id }
      }).href;

      window.open(serverDetailUrl);
    },
    getServers,
    deleteServers,
    postServer,
    select (item) {
      item.selected = !item.selected
    },
    unselectAll () {
      this.servers.forEach((server) => (server.selected = false))
    },
    cleanForm: function () {
      this.server_info = {
        username: '',
        password: '',
        password2: ''
      }
    },
    closeDialog,
    closeUpdateDialog,
    setIsUpdateDialogOpen
  },
  mounted () {
    console.log('mounte server')
    this.getServers()
  }
}
</script>
