import request from '@/utils/request'
import { parseTime, addSelectField } from '@/utils/index'

export const postServer = function (server_info) {
  if (server_info.username === '') {
    this.is_dialog_open = true
    this.dialog_content = '服务器名不得为空'
  } else if (server_info.password === '') {
    this.is_dialog_open = true
    this.dialog_content = '密码不得为空'
  } else if (server_info.password !== server_info.password2) {
    this.is_dialog_open = true
    this.dialog_content = '两次密码不一致'
  } else {
    request.post(`/api/admin/servers`, {
      server_info
    })
      .then((response) => {
        console.log('post ok', response)
        this.is_dialog_open = true
        this.dialog_content = '新建成功'
        this.getServers()
      })
      .catch((errors) => {
        console.log('post error', errors)
        this.errors = errors
        this.is_dialog_open = true
        this.dialog_content = '新建失败'
      })
  }
}
export const getServers = function () {
  request.get(`/api/admin/servers`, {
  })
    .then((response) => {
      let servers = response.data.servers
      // console.log(servers)
      for (let s of servers) {
        s.last_login = parseTime(s.last_login)
        s.online = false
        let now = parseTime(new Date())
        if ((new Date(now) - new Date(s.last_login)) < 60 * 1000) {
          s.online = true
        }
        s.total_memory = s.total_memory
        s.processes_info = s.processes_info
        console.log(s.processes_info)
        if (s.processes_info == null) continue
        for (let i = 0; i < s.processes_info.length; i++) {
          let colors = [
            '#006400',
            '#008000',
            '#2e8b57',
            '#228b22',
            '#3cb371',
            '#32cd32',
            '#adff2f',
            '#7cfc00',
            '#98fb98',
            '#90ee90'
          ]
          s.processes_info[i].color = colors[i % colors.length]
        }
      }
      servers = servers.map(addSelectField)
      for (let server of servers) {
        server.link = '详情'
      }
      console.log('getservers')
      this.servers = servers
    })
    .catch((errors) => {
      console.log('error', errors)
      this.servers = []
      this.errors = errors
    })
}


let option = {
  tooltip: {
    trigger: "item",
    formatter: "{a} <br/>{b} : {c} ({d}%)",
  },
  color:['#ef6567', '#3BA272'],
  series : [
    {
      name:'GPU显存信息',
      type: 'pie' ,
      radius: '90%',
      data: [
        {value: 40,  name:'已用显存'},
        {value: 60,  name:'剩余显存'},
      ],
      // center: ["40%", "60%"],
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)",
        },
      },
    }
  ]
};


export const deleteServers = function () {
  let server_ids = []
  this.servers.forEach(server => { if (server.selected) { server_ids.push(server.id) } })
  console.log('selected', server_ids)
  request.delete(`/api/admin/servers`, {
    params: {
      server_ids: server_ids
    }
  })
    .then((response) => {
      console.log('delete success')
      this.is_dialog_open = true
      this.dialog_content = '删除成功'
      this.getServers()
    })
    .catch((errors) => {
      console.log('delete failure')
      this.errors = errors
      this.is_dialog_open = true
      this.dialog_content = '删除失败'
      this.getServers()
    })
}
